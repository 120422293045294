import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import Moment from 'moment';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';
import {useInput} from '../../Components/Hooks/Input';
import Alert,{alertShow} from '../../Components/Alert';

// models
//import {Clients} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import { phoneFormatter, yearsOldGet } from '../../Globals/Utils';

// globals
import { clienActivityType, clienActivityTypeName, clientDocumentType, clientDocumentTypeName, clientEducation, clientEducationName, clientEmployment, clientEmploymentName, clientFamilyStatus, clientFamilyStatusName, clientPensionType, clientPensionTypeName, clientType, clientTypeName, commonStatus, commonStatusName, userGender, userGenderName } from '../../Globals/Constants';

// images
import iconYmap from '../../Assets/Images/icons/ymap.svg';
import iconCheck from '../../Assets/Images/icons/check-circle.svg';


// styles
import './styles.css';

const ClientScreen = (props) => {
	const [id] = useState(props.match.params.id||0);
	const [tab, setTab] = useState(1);
	const [accordion, setAccordion] = useState([{id:2,isopen:true}]);
	const [isEdit, setIsEdit] = useState(false);
	const [client, setClient] = useState(null);


	const {value:firstName, setValue:setFirstName, bind:bindFirstName} = useInput('');
	const {value:lastName, setValue:setLastName, bind:bindLastName} = useInput('');
	const {value:middleName, setValue:setMiddleName, bind:bindMiddleName} = useInput('');
	const {value:maidenName, setValue:setMaidenName, bind:bindMaidenName} = useInput('');

	const {value:birthDate, setValue:setBirthDate, bind:bindBirthDate} = useInput('');
	const {value:birthPlace, setValue:setBirthPlace, bind:bindBirthPlace} = useInput('');
	const {value:birthCountry, setValue:setBirthCountry, bind:bindBirthCountry} = useInput('');

	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:phoneAdditional, setValue:setPhoneAdditional, bind:bindPhoneAdditional} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const {value:INN, setValue:setINN, bind:bindINN} = useInput('');
	const {value:SNILS, setValue:setSNILS, bind:bindSNILS} = useInput('');

	const {value:highSchool, setValue:setHighSchool, bind:bindHighSchool} = useInput('');
	const {value:studyForm, setValue:setStudyForm, bind:bindStudyForm} = useInput('');
	const {value:faculty, setValue:setFaculty, bind:bindFaculty} = useInput('');
	const {value:studyGraduationYear, setValue:setStudyGraduationYear, bind:bindStudyGraduationYear} = useInput('');


	const {value:passportCountry, setValue:setPassportCountry, bind:bindPassportCountry} = useInput('');
	const {value:passportSeries, setValue:setPassportSeries, bind:bindPassportSeries} = useInput('');
	const {value:passportNumber, setValue:setPassportNumber, bind:bindPassportNumber} = useInput('');
	const {value:passportCode, setValue:setPassportCode, bind:bindPassportCode} = useInput('');
	const {value:passportDate, setValue:setPassportDate, bind:bindPassportDate} = useInput('');
	const {value:passportExpire, setValue:setPassportExpire, bind:bindPassportExpire} = useInput('');
	const {value:passportIssuer, setValue:setPassportIssuer, bind:bindPassportIssuer} = useInput('');
	const {value:passportOldPassport, setValue:setPassportOldPassport, bind:bindPassportOldPassport} = useInput('');

	const {value:complementaryDocument, setValue:setComplementaryDocument, bind:bindComplementaryDocument} = useInput('');
	const {value:complementaryDocumentDate, setValue:setComplementaryDocumentDate, bind:bindComplementaryDocumentDate} = useInput('');
	const {value:complementaryDocumentExpire, setValue:setComplementaryDocumentExpire, bind:bindComplementaryDocumentExpire} = useInput('');
	const {value:complementaryDocumentIssuer, setValue:setComplementaryDocumentIssuer, bind:bindComplementaryDocumentIssuer} = useInput('');

	const {value:monthlyPension, setValue:setMonthlyPension, bind:bindMonthlyPension} = useInput('');

	const {value:ipRegistrationSeries, setValue:setIpRegistrationSeries, bind:bindIpRegistrationSeries} = useInput('');
	const {value:ipRegistrationNumber, setValue:setIpRegistrationNumber, bind:bindIpRegistrationNumber} = useInput('');
	const {value:ipRegistrationDate, setValue:setIpRegistrationDate, bind:bindIpRegistrationDate} = useInput('');
	const {value:ipRegistrationIssuer, setValue:setIpRegistrationIssuer, bind:bindIpRegistrationIssuer} = useInput('');


	const {value:registrationAddressCountry, setValue:setRegistrationAddressCountry, bind:bindRegistrationAddressCountry} = useInput('');
	const {value:registrationAddressHousingType, setValue:setRegistrationAddressHousingType, bind:bindRegistrationAddressHousingType} = useInput('');
	const {value:registrationAddressDate, setValue:setRegistrationAddressDate, bind:bindRegistrationAddressDate} = useInput('');
	const {value:registrationAddressAddress, setValue:setRegistrationAddressAddress, bind:bindRegistrationAddressAddress} = useInput('');
	const {value:registrationAddressOldAddress, setValue:setRegistrationAddressOldAddress, bind:bindRegistrationAddressOldAddress} = useInput('');
	const {value:registrationAddressIndex, setValue:setRegistrationAddressIndex, bind:bindRegistrationAddressIndex} = useInput('');
	const {value:registrationAddressRegion, setValue:setRegistrationAddressRegion, bind:bindRegistrationAddressRegion} = useInput('');
	const {value:registrationAddressCity, setValue:setRegistrationAddressCity, bind:bindRegistrationAddressCity} = useInput('');
	const {value:registrationAddressStreet, setValue:setRegistrationAddressStreet, bind:bindRegistrationAddressStreet} = useInput('');
	const {value:registrationAddressHouse, setValue:setRegistrationAddressHouse, bind:bindRegistrationAddressHouse} = useInput('');
	const {value:registrationAddressBlock, setValue:setRegistrationAddressBlock, bind:bindRegistrationAddressBlock} = useInput('');
	const {value:registrationAddressBuilding, setValue:setRegistrationAddressBuilding, bind:bindRegistrationAddressBuilding} = useInput('');
	const {value:registrationAddressFlat, setValue:setRegistrationAddressFlat, bind:bindRegistrationAddressFlat} = useInput('');
	const {value:registrationAddressMetroStation, setValue:setRegistrationAddressMetroStation, bind:bindRegistrationAddressMetroStation} = useInput('');

	const {value:livingAddressCountry, setValue:setLivingAddressCountry, bind:bindLivingAddressCountry} = useInput('');
	const {value:livingAddressHousingType, setValue:setLivingAddressHousingType, bind:bindLivingAddressHousingType} = useInput('');
	const {value:livingAddressDate, setValue:setLivingAddressDate, bind:bindLivingAddressDate} = useInput('');
	const {value:livingAddressAddress, setValue:setLivingAddressAddress, bind:bindLivingAddressAddress} = useInput('');
	const {value:livingAddressOldAddress, setValue:setLivingAddressOldAddress, bind:bindLivingAddressOldAddress} = useInput('');
	const {value:livingAddressIndex, setValue:setLivingAddressIndex, bind:bindLivingAddressIndex} = useInput('');
	const {value:livingAddressRegion, setValue:setLivingAddressRegion, bind:bindLivingAddressRegion} = useInput('');
	const {value:livingAddressCity, setValue:setLivingAddressCity, bind:bindLivingAddressCity} = useInput('');
	const {value:livingAddressStreet, setValue:setLivingAddressStreet, bind:bindLivingAddressStreet} = useInput('');
	const {value:livingAddressHouse, setValue:setLivingAddressHouse, bind:bindLivingAddressHouse} = useInput('');
	const {value:livingAddressBlock, setValue:setLivingAddressBlock, bind:bindLivingAddressBlock} = useInput('');
	const {value:livingAddressBuilding, setValue:setLivingAddressBuilding, bind:bindLivingAddressBuilding} = useInput('');
	const {value:livingAddressFlat, setValue:setLivingAddressFlat, bind:bindLivingAddressFlat} = useInput('');
	const {value:livingAddressMetroStation, setValue:setLivingAddressMetroStation, bind:bindLivingAddressMetroStation} = useInput('');


	const {value:workOrganizationName, setValue:setWorkOrganizationName, bind:bindWorkOrganizationName} = useInput('');
	const {value:workPosition, setValue:setWorkPosition, bind:bindWorkPosition} = useInput('');
	const {value:workProfession, setValue:setWorkProfession, bind:bindWorkProfession} = useInput('');
	const {value:workDateStart, setValue:setWorkDateStart, bind:bindWorkDateStart} = useInput('');
	const {value:workDateNextSalary, setValue:setWorkDateNextSalary, bind:bindWorkDateNextSalary} = useInput('');
	const {value:workINN, setValue:setWorkINN, bind:bindWorkINN} = useInput('');
	const {value:workPhone, setValue:setWorkPhone, bind:bindWorkPhone} = useInput('');
	const {value:workBossName, setValue:setWorkBossName, bind:bindWorkBossName} = useInput('');
	const {value:workColleaguePhone, setValue:setWorkColleaguePhone, bind:bindWorkColleaguePhone} = useInput('');
	const {value:workDirectorPhone, setValue:setWorkDirectorPhone, bind:bindWorkDirectorPhone} = useInput('');
	const {value:workWebsite, setValue:setWorkWebsite, bind:bindWorkWebsite} = useInput('');
	const {value:workPreviousPlace, setValue:setWorkPreviousPlace, bind:bindWorkPreviousPlace} = useInput('');
	const {value:workNotWorkReason, setValue:setWorkNotWorkReason, bind:bindWorkNotWorkReason} = useInput('');
	const {value:workComment, setValue:setWorkComment, bind:bindWorkComment} = useInput('');


	const {value:familyChildrenCount, setValue:setFamilyChildrenCount, bind:bindFamilyChildrenCount} = useInput('');
	const {value:familyAdultChildrenCount, setValue:setFamilyAdultChildrenCount, bind:bindFamilyAdultChildrenCount} = useInput('');
	const {value:familyDependentsCount, setValue:setFamilyDependentsCount, bind:bindFamilyDependentsCount} = useInput('');
	const {value:familyChildrenAge, setValue:setFamilyChildrenAge, bind:bindFamilyChildrenAge} = useInput('');

	const {value:incomeAverage, setValue:setIncomeAverage, bind:bindIncomeAverage} = useInput('');
	const {value:incomeAdditional, setValue:setIncomeAdditional, bind:bindIncomeAdditional} = useInput('');
	const {value:incomeBonus, setValue:setIncomeBonus, bind:bindIncomeBonus} = useInput('');
	const {value:incomeRent, setValue:setIncomeRent, bind:bindIncomeRent} = useInput('');

	const {value:expenseAverage, setValue:setExpenseAverage, bind:bindExpenseAverage} = useInput('');
	const {value:expenseAlimony, setValue:setExpenseAlimony, bind:bindExpenseAlimony} = useInput('');
	const {value:expenseLoans, setValue:setExpenseLoans, bind:bindExpenseLoans} = useInput('');
	const {value:expenseOther, setValue:setExpenseOther, bind:bindExpenseOther} = useInput('');

	const {value:income2NDFL, setValue:setIncome2NDFL, bind:bindIncome2NDFL} = useInput('');

	
	const {value:propertyMarketValue, setValue:setPropertyMarketValue, bind:bindPropertyMarketValue} = useInput('');
	const {value:propertyAcquisitionMethod, setValue:setPropertyAcquisitionMethod, bind:bindPropertyAcquisitionMethod} = useInput('');
	const {value:propertyComment, setValue:setPropertyComment, bind:bindPropertyComment} = useInput('');

	const {value:autoMarketValue, setValue:setAutoMarketValue, bind:bindAutoMarketValue} = useInput('');
	const {value:autoAcquisitionMethod, setValue:setAutoAcquisitionMethod, bind:bindAutoAcquisitionMethod} = useInput('');
	const {value:autoComment, setValue:setAutoComment, bind:bindAutoComment} = useInput('');

	const {value:additionalIncomeSum, setValue:setAdditionalIncomeSum, bind:bindAdditionalIncomeSum} = useInput('');
	const {value:additionalIncomeSource, setValue:setAdditionalIncomeSource, bind:bindAdditionalIncomeSource} = useInput('');




	const [gender, setGender] = useState(userGender.UNSET);
	const [familyStatus, setFamilyStatus] = useState(clientFamilyStatus.UNSET);
	const [education, setEducation] = useState(clientEducation.UNSET);
	const [complementaryDocumentType, setComplementaryDocumentType] = useState(clientDocumentType.UNSET);
	const [pensionType, setPensionType] = useState(clientPensionType.UNSET);

	const [workPlaceType, setWorkPlaceType] = useState(clientEmployment.UNSET);
	const [workActivityType, setWorkActivityType] = useState(clienActivityType.UNSET);


	const [businessOwner, setBusinessOwner] = useState('');
	const [businessman, setBusinessman] = useState('');
	const [MSP, setMSP] = useState('');



	///
	const {value:clientComment, setValue:setClientComment, bind:bindClientComment} = useInput('');




	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const client = {
				id:1234027,
				name:'Вакулова Алла Сергеевна',
				firstName:'Алла',
				lastName:'Вакулова',
				middleName:'Сергеевна',
				maidenName:'Вакулова',
				gender:gender.FEMALE,
				birthDate:'1988-07-25',
				phone:'79185345126',
				passport:'6009-649683',
				addressLive:'344006, Россия, г Ростов-на-Дону, пр-кт Чехова, д 56/37, кв 18',
				addressRegistration:'344006, Россия, г Ростов-на-Дону, пр-кт Чехова, д 56/37, кв 18',
				INN:'616305505000',
				SNILS:'142-735-446 57',
				type:clientType.BORROWER,
				image:'https://fs-green-k8s.brainysoft.ru/web/files/thumb/capitalina/12882790/6354ec6a001193855ea30006c6c69ec07fcfab6c9a589169c4fd2a9337c93f90dbd364c13e4e0038847e6705502d93a898f69bd5e65315e27b5e69e440863a5d',
				createdAt:'2024-01-25 12:43:07'
			};
			setClient(client);


			setFirstName(client.firstName);
			setLastName(client.lastName);

			setRegistrationAddressAddress(client.addressRegistration)
			setLivingAddressAddress(client.addressLive)

			setLoading(false);
		};
		
		return async () => {
			await dataGet();
		};
	}, []);


	const isAccordionOpen = (id) => {
		const a = accordion ? accordion.find(f => f.id === id) : null;
		return a ? a.isopen : false;
	}
	const accordionSet = (id) => {
		if (accordion.find(f => f.id === id)) {
			accordion.forEach((v) => {
				if (v.id === id) {
					if (v.isopen) v.isopen = false;
					else v.isopen = true;
				}
			});
			setAccordion([...accordion]);
		} else setAccordion([...accordion,{id,isopen:true}]);
	}

	const selectHandleGender = (e) => setGender(parseInt(e.target.value));
	const selectHandleFamilyStatus = (e) => setFamilyStatus(parseInt(e.target.value));
	const selectHandleEducation = (e) => setEducation(parseInt(e.target.value));
	const selectHandleComplementaryDocument = (e) => setComplementaryDocumentType(parseInt(e.target.value));
	const selectHandlePension = (e) => setPensionType(parseInt(e.target.value));
	const selectHandleWorkPlaceType = (e) => setWorkPlaceType(parseInt(e.target.value));
	const selectHandleWorkActivityType = (e) => setWorkActivityType(parseInt(e.target.value));

	const checkHandleBusinessOwner = (e) => setBusinessOwner(e.target.checked);
	const checkHandleBusinessman = (e) => setBusinessman(e.target.checked);
	const checkHandleMSP = (e) => setMSP(e.target.checked);



	
	//const selectHandleStatus = (e) => setStatus(parseInt(e.target.value));
	

	const save = async (e) => {
		e.preventDefault();
		alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
	}


	const leftSideContent = () => {
		return <div className="block">

		</div>;
	}



	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<form className="client-container">
					<div className="blocks">
						<div className="client-tabs-menu">
							<ul>
								<li className={tab===1?'selected':null}><Link to={'#'} onClick={() => setTab(1)}>О клиенте</Link></li>
								<li className={tab===2?'selected':null}><Link to={'#'} onClick={() => setTab(2)}>Заявки</Link></li>
								<li className={tab===3?'selected':null}><Link to={'#'} onClick={() => setTab(3)}>Контракты</Link></li>
								<li className={tab===4?'selected':null}><Link to={'#'} onClick={() => setTab(4)}>Мониторинг</Link></li>
								<li className={tab===5?'selected':null}><Link to={'#'} onClick={() => setTab(5)}>Лиды</Link></li>
								<li className={tab===6?'selected':null}><Link to={'#'} onClick={() => setTab(6)}>Статусы клиента</Link></li>
								<li className={tab===7?'selected':null}><Link to={'#'} onClick={() => setTab(7)}>Файлы</Link></li>
								<li className={tab===8?'selected':null}><Link to={'#'} onClick={() => setTab(8)}>Связи</Link></li>
								<li className={tab===9?'selected':null}><Link to={'#'} onClick={() => setTab(9)}>SMS</Link></li>
							</ul>
							{tab === 1 ?
									<div className="client-action-menu">
										<div className="client-action-menu-item client-action-menu-edit">
											<Link to={'#'} onClick={() => setIsEdit(true)}>Редактировать</Link>
										</div>
										<div className="client-action-menu-item client-action-menu-edit">
											<Link to={'#'} onClick={() => setIsEdit(true)}>Создать</Link>
										</div>
										<div className="client-action-menu-item client-action-menu-edit">
											<Link to={'#'} onClick={() => setIsEdit(true)}>Проверить в ФССП</Link>
										</div>
										<div className="client-action-menu-item client-action-menu-edit">
											<Link to={'#'} onClick={() => setIsEdit(true)}>Печать</Link>
										</div>
										<div className="client-action-menu-item client-action-menu-edit">
											<Link to={'#'} onClick={() => setIsEdit(true)}>Аудит</Link>
										</div>
									</div>
								: null}
						</div>

						<div className="main-block">
							<div className="main-block-inner">
								<div className="client-info">
									{tab === 1 ?
										<>
											<h3>{client.name}</h3>
											<div className="client-info-badges">
												<div className="client-info-badge">
													ID {client.id}
												</div>
												<div className="client-info-badge">
													Регистрация от {Moment(client.createdAt).format('DD.MM.y')}
												</div>
												<div className="client-info-badge client-info-badge-usertype">
													{clientTypeName[client.type]}
												</div>
											</div>
										</> : null}
									<div className="client-info-inner">
										<div className="photo-block">
											<img src={client.image} alt={client.name} />
										</div>
										<div className="user-block">
											{tab !== 1 ? <h4>{client.name}</h4> : null}
											<dl>
												<dt>Пол</dt>
												<dd>{userGenderName[client.gender]}</dd>
												<dt>Дата рождения</dt>
												<dd>{Moment(client.birthDate).format('DD.MM.y')} ({yearsOldGet(client.birthDate)})</dd>
												<dt>Паспорт</dt>
												<dd>6009-649683</dd>
												<dt>Регистрация</dt>
												<dd className="user-block-oneline">
													<a href={`https://yandex.ru/maps/?text=${client.addressRegistration}`} target="_blank"><img src={iconYmap} alt="Яндекс карты" className="ymaps" /></a>
													{client.addressRegistration}
												</dd>
												<dt>Проживает</dt>
												<dd className="user-block-oneline">
													<a href={`https://yandex.ru/maps/?text=${client.addressLive}`} target="_blank"><img src={iconYmap} alt="Яндекс карты" className="ymaps" /></a>
													{client.addressLive}
												</dd>
												<dt>ИНН</dt>
												<dd>{client.INN}</dd>
												<dt>СНИЛС</dt>
												<dd>{client.SNILS}</dd>
												<dt>Телефон</dt>
												<dd>{phoneFormatter(client.phone)}</dd>
											</dl>
										</div>
									</div>
								</div>


								<div className="blocks-inner">
									{tab === 1 ?
										<div className="tab-client-block">

											<div className="accordion-block">
												<div className="accordion">
													<div className="accordion-title" onClick={() => accordionSet(1)}>
														<span>Загруженные файлы</span>
														<span className={isAccordionOpen(1)?"accordion-arrow-down":"accordion-arrow-right"}></span>
													</div>
													{isAccordionOpen(1) ?
														<div className="accordion-content">

															1




														</div> : null}
												</div>
												<div className="accordion">
													<div className="accordion-title" onClick={() => accordionSet(2)}>
														<span>Персональные данные</span>
														<span className={isAccordionOpen(2)?"accordion-arrow-down":"accordion-arrow-right"}></span>
													</div>
													{isAccordionOpen(2) ?
														<div className="accordion-content">
															<div className="form-container">
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Имя</span>
																			<input type="text" {...bindFirstName} placeholder="Имя" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Фамилия</span>
																			<input type="text" {...bindLastName} placeholder="Фамилия" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Отчество</span>
																			<input type="text" {...bindMiddleName} placeholder="Отчество" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Пол</span>
																			<select className="select-minimal" onChange={selectHandleGender} value={client.gender}>
																				{userGenderName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																			</select>
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Девичья фамилия</span>
																			<input type="text" {...bindMaidenName} placeholder="Девичья фамилия" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Дата рождения</span>
																			<input type="datetime-local" {...bindBirthDate} placeholder="10.08.1990" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Место рождения</span>
																			<input type="text" {...bindBirthPlace} placeholder="Место рождения" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Страна рождения</span>
																			<input type="text" {...bindBirthCountry} placeholder="Страна рождения" required />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Семейное положение</span>
																			<select className="select-minimal" onChange={selectHandleFamilyStatus} value={client.familyStatus}>
																				{clientFamilyStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																			</select>
																		</label>
																	</div>
																</div>
																<div className="form-title">Контактные данные</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Телефон</span>
																			<input type="tel" {...bindPhone} placeholder="Телефон" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дополнительный телефон</span>
																			<input type="tel" {...bindPhoneAdditional} placeholder="Дополнительный телефон" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Email</span>
																			<input type="email" {...bindEmail} placeholder="Email" />
																		</label>
																	</div>
																</div>
																<div className="form-title">Финансовые данные</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>ИНН</span>
																			<input type="text" {...bindINN} placeholder="ИНН" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>СНИЛС</span>
																			<input type="text" {...bindSNILS} placeholder="СНИЛС" required />
																		</label>
																	</div>
																</div>
																<div className="form-title">Образование</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Образование</span>
																			<select className="select-minimal" onChange={selectHandleEducation} value={client.education}>
																				{clientEducationName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																			</select>
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>ВУЗ</span>
																			<input type="text" {...bindHighSchool} placeholder="ВУЗ" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Форма обучения</span>
																			<input type="text" {...bindStudyForm} placeholder="Форма обучения" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Факультет</span>
																			<input type="text" {...bindFaculty} placeholder="Факультет" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Год окончания учебы</span>
																			<input type="text" {...bindStudyGraduationYear} placeholder="Год окончания учебы" maxLength={4} />
																		</label>
																	</div>
																</div>
															</div>
														</div> : null}
												</div>
												<div className="accordion">
													<div className="accordion-title" onClick={() => accordionSet(3)}>
														<span>Документы</span>
														<span className={isAccordionOpen(3)?"accordion-arrow-down":"accordion-arrow-right"}></span>
													</div>
													{isAccordionOpen(3) ?
														<div className="accordion-content">
															<div className="form-container">
																<div className="form-title">Паспорт (основной документ)</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Страна гражданства</span>
																			<input type="text" {...bindPassportCountry} placeholder="Страна гражданства" required />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Серия</span>
																			<input type="text" {...bindPassportSeries} placeholder="Серия" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Номер</span>
																			<input type="text" {...bindPassportNumber} placeholder="Номер" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Код подразделения</span>
																			<input type="text" {...bindPassportCode} placeholder="Код подразделения" required />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Дата выдачи</span>
																			<input type="datetime-local" {...bindPassportDate} placeholder="Дата выдачи" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дата истечения</span>
																			<input type="datetime-local" {...bindPassportExpire} placeholder="Дата истечения" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Кем выдан</span>
																			<input type="text" {...bindPassportIssuer} placeholder="Кем выдан" className="large" required />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Ранее выданные паспорта</span>
																			<input type="text" {...bindPassportOldPassport} placeholder="Ранее выданные паспорта" className="large" required />
																		</label>
																	</div>
																</div>
																<div className="form-title">Дополнительный документ</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Тип дополнительного документа</span>
																			<select className="select-minimal" onChange={selectHandleComplementaryDocument} value={client.complementaryDocumentType}>
																				{clientDocumentTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																			</select>
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Номер</span>
																			<input type="text" {...bindComplementaryDocument} placeholder="Номер" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дата выдачи</span>
																			<input type="datetime-local" {...bindComplementaryDocumentDate} placeholder="Дата выдачи" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дата истечения</span>
																			<input type="datetime-local" {...bindComplementaryDocumentExpire} placeholder="Дата истечения" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Кем выдан</span>
																			<input type="text" {...bindComplementaryDocumentIssuer} placeholder="Кем выдан" className="large" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Вид пенсии</span>
																			<select className="select-minimal" onChange={selectHandlePension} value={client.pensionType}>
																				{clientPensionTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																			</select>
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Среднемесячный размер пенсии</span>
																			<input type="text" {...bindMonthlyPension} placeholder="Среднемесячный размер пенсии" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label className="checkbox">
																			<input type="checkbox" onChange={checkHandleBusinessOwner} />
																			<span>Собственник бизнеса</span>
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label className="checkbox">
																			<input type="checkbox" onChange={checkHandleBusinessman} />
																			<span>ИП</span>
																		</label>
																	</div>
																</div>
																{businessman ?
																	<div className="row">
																		<div className="cell">
																			<label className="checkbox">
																				<input type="checkbox" onChange={checkHandleMSP} />
																				<span>МСП</span>
																			</label>
																		</div>
																	</div> : null}
																{businessman ?
																	<>
																		<div className="form-title">ОГРНИП</div>
																		<div className="row">
																			<div className="cell">
																				<label>
																					<span>Серия</span>
																					<input type="text" {...bindIpRegistrationSeries} placeholder="Серия" />
																				</label>
																			</div>
																			<div className="cell">
																				<label>
																					<span>Номер</span>
																					<input type="text" {...bindIpRegistrationNumber} placeholder="Номер" />
																				</label>
																			</div>
																			<div className="cell">
																				<label>
																					<span>Дата регистрации</span>
																					<input type="datetime-local" {...bindIpRegistrationDate} placeholder="Дата регистрации" />
																				</label>
																			</div>
																		</div>
																		<div className="row">
																			<div className="cell">
																				<label>
																					<span>Кем выдан</span>
																					<input type="text" {...bindIpRegistrationIssuer} placeholder="Кем выдан" className="large" />
																				</label>
																			</div>
																		</div>
																		<div className="row">
																			<div className="cell">
																				<label>
																					<span>Номер ОГРН</span>
																					<input type="text" {...bindComplementaryDocumentIssuer} placeholder="Номер ОГРН" className="large" />
																				</label>
																			</div>
																		</div>
																	</> : null}
															</div>
														</div> : null}
												</div>
												<div className="accordion">
													<div className="accordion-title" onClick={() => accordionSet(4)}>
														<span>Адреса и реквизиты</span>
														<span className={isAccordionOpen(4)?"accordion-arrow-down":"accordion-arrow-right"}></span>
													</div>
													{isAccordionOpen(4) ?
														<div className="accordion-content">
															<div className="form-container">
																<div className="form-title">Адрес прописки</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Страна</span>
																			<input type="text" {...bindRegistrationAddressCountry} placeholder="Страна" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Тип жилья</span>
																			<input type="text" {...bindRegistrationAddressHousingType} placeholder="Тип жилья" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дата регистрации</span>
																			<input type="datetime-local" {...bindRegistrationAddressDate} placeholder="Дата регистрации" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Строка адреса ФИАС</span>
																			<input type="text" {...bindRegistrationAddressAddress} placeholder="Строка адреса ФИАС" className="large" required />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Старый адрес</span>
																			<input type="text" {...bindRegistrationAddressOldAddress} placeholder="Старый адрес" className="large" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Почтовый индекс</span>
																			<input type="text" {...bindRegistrationAddressIndex} placeholder="Почтовый индекс" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Область, Регион, Республика</span>
																			<input type="text" {...bindRegistrationAddressRegion} placeholder="Область, Регион, Республика" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Населенный пункт</span>
																			<input type="text" {...bindRegistrationAddressCity} placeholder="Населенный пункт" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Улица</span>
																			<input type="text" {...bindRegistrationAddressStreet} placeholder="Улица" className="double" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Ближайшая станция метро</span>
																			<input type="text" {...bindRegistrationAddressMetroStation} placeholder="Ближайшая станция метро" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Дом</span>
																			<input type="text" {...bindRegistrationAddressHouse} placeholder="Дом" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Корпус</span>
																			<input type="text" {...bindRegistrationAddressBlock} placeholder="Корпус" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Строение</span>
																			<input type="text" {...bindRegistrationAddressBuilding} placeholder="Строение" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Квартира</span>
																			<input type="text" {...bindRegistrationAddressFlat} placeholder="Квартира" className="small" />
																		</label>
																	</div>
																</div>
																<div className="form-title">Адрес проживания</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Страна</span>
																			<input type="text" {...bindLivingAddressCountry} placeholder="Страна" required />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Тип жилья</span>
																			<input type="text" {...bindLivingAddressHousingType} placeholder="Тип жилья" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дата регистрации</span>
																			<input type="datetime-local" {...bindLivingAddressDate} placeholder="Дата регистрации" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Строка адреса ФИАС</span>
																			<input type="text" {...bindLivingAddressAddress} placeholder="Строка адреса ФИАС" className="large" required />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Старый адрес</span>
																			<input type="text" {...bindLivingAddressOldAddress} placeholder="Старый адрес" className="large" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Почтовый индекс</span>
																			<input type="text" {...bindLivingAddressIndex} placeholder="Почтовый индекс" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Область, Регион, Республика</span>
																			<input type="text" {...bindLivingAddressRegion} placeholder="Область, Регион, Республика" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Населенный пункт</span>
																			<input type="text" {...bindLivingAddressCity} placeholder="Населенный пункт" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Улица</span>
																			<input type="text" {...bindLivingAddressStreet} placeholder="Улица" className="double" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Ближайшая станция метро</span>
																			<input type="text" {...bindLivingAddressMetroStation} placeholder="Ближайшая станция метро" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Дом</span>
																			<input type="text" {...bindLivingAddressHouse} placeholder="Дом" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Корпус</span>
																			<input type="text" {...bindLivingAddressBlock} placeholder="Корпус" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Строение</span>
																			<input type="text" {...bindLivingAddressBuilding} placeholder="Строение" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Квартира</span>
																			<input type="text" {...bindLivingAddressFlat} placeholder="Квартира" className="small" />
																		</label>
																	</div>
																</div>
															</div>
														</div> : null}
												</div>

												<div className="accordion">
													<div className="accordion-title" onClick={() => accordionSet(5)}>
														<span>Информация о месте работы</span>
														<span className={isAccordionOpen(5)?"accordion-arrow-down":"accordion-arrow-right"}></span>
													</div>
													{isAccordionOpen(5) ?
														<div className="accordion-content">
															<div className="form-container">
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Название организации</span>
																			<input type="text" {...bindWorkOrganizationName} placeholder="Название организации" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Тип места работы</span>
																			<select className="select-minimal" onChange={selectHandleWorkPlaceType} value={client.workPlaceType}>
																				{clientEmploymentName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																			</select>
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Вид деятельности</span>
																			<select className="select-minimal" onChange={selectHandleWorkActivityType} value={client.workActivityType}>
																				{clienActivityTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
																			</select>
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Должность</span>
																			<input type="text" {...bindWorkPosition} placeholder="Должность" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Профессия</span>
																			<input type="text" {...bindWorkProfession} placeholder="Профессия" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Дата трудоустройства</span>
																			<input type="datetime-local" {...bindWorkDateStart} placeholder="Дата трудоустройства" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дата следующей з/п</span>
																			<input type="datetime-local" {...bindWorkDateNextSalary} placeholder="Дата следующей з/п" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>ИНН организации</span>
																			<input type="text" {...bindWorkINN} placeholder="ИНН организации" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Телефон организации</span>
																			<input type="tel" {...bindWorkPhone} placeholder="Телефон организации" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>ФИО руководителя</span>
																			<input type="text" {...bindWorkBossName} placeholder="ФИО руководителя" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Телефон коллеги</span>
																			<input type="tel" {...bindWorkColleaguePhone} placeholder="Телефон коллеги" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Телефон директора</span>
																			<input type="tel" {...bindWorkDirectorPhone} placeholder="Телефон директора" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Веб-сайт работы</span>
																			<input type="text" {...bindWorkWebsite} placeholder="Веб-сайт работы" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Предыдущее место работы</span>
																			<input type="text" {...bindWorkPreviousPlace} placeholder="Предыдущее место работы" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Не работаю по причине</span>
																			<input type="text" {...bindWorkNotWorkReason} placeholder="Не работаю по причине" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Информация о работе клиента</span>
																			<textarea {...bindWorkComment} className="desc" placeholder="Информация о работе клиента" required></textarea>
																		</label>
																	</div>
																</div>
															</div>
														</div> : null}
												</div>

												<div className="accordion">
													<div className="accordion-title" onClick={() => accordionSet(6)}>
														<span>Дополнительная информация</span>
														<span className={isAccordionOpen(6)?"accordion-arrow-down":"accordion-arrow-right"}></span>
													</div>
													{isAccordionOpen(6) ?
														<div className="accordion-content">
															<div className="form-container">
																<div className="form-title">Дети</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Количество детей</span>
																			<input type="text" {...bindFamilyChildrenCount} placeholder="Количество детей" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Возраст детей</span>
																			<input type="text" {...bindFamilyChildrenAge} placeholder="Возраст детей" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дети старше 18 лет</span>
																			<input type="text" {...bindFamilyAdultChildrenCount} placeholder="Дети старше 18 лет" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дети младше 18 лет</span>
																			<input type="text" {...bindFamilyDependentsCount} placeholder="Дети младше 18 лет" className="small" />
																		</label>
																	</div>
																</div>
																<div className="form-title">Доходы</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Месячный доход</span>
																			<input type="text" {...bindIncomeAverage} placeholder="Месячный доход" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Дополнительный доход</span>
																			<input type="text" {...bindIncomeAdditional} placeholder="Дополнительный доход" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Премии</span>
																			<input type="text" {...bindIncomeBonus} placeholder="Премии" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Доход от аренды</span>
																			<input type="text" {...bindIncomeRent} placeholder="Доход от аренды" className="small" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Ежемесячный доход по 2НДФЛ</span>
																			<input type="text" {...bindIncome2NDFL} placeholder="Ежемесячный доход по 2НДФЛ" />
																		</label>
																	</div>
																</div>
																<div className="form-title">Расходы</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Месячные расходы</span>
																			<input type="text" {...bindExpenseAverage} placeholder="Месячные расходы" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Алименты</span>
																			<input type="text" {...bindExpenseAlimony} placeholder="Алименты" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Выплаты по кредитам</span>
																			<input type="text" {...bindExpenseLoans} placeholder="Выплаты по кредитам" className="small" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Прочие расходы</span>
																			<input type="text" {...bindExpenseOther} placeholder="Прочие расходы" className="small" />
																		</label>
																	</div>
																</div>
															</div>
														</div> : null}
												</div>



												<div className="accordion">
													<div className="accordion-title" onClick={() => accordionSet(7)}>
														<span>Собственность</span>
														<span className={isAccordionOpen(7)?"accordion-arrow-down":"accordion-arrow-right"}></span>
													</div>
													{isAccordionOpen(7) ?
														<div className="accordion-content">
															<div className="form-container">
																<div className="form-title">Недвижимость</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Рыночная стоимость</span>
																			<input type="text" {...bindPropertyMarketValue} placeholder="Рыночная стоимость" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Способ приобретения</span>
																			<input type="text" {...bindPropertyAcquisitionMethod} placeholder="Способ приобретения" className="double" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Комментарий</span>
																			<textarea {...bindPropertyComment} className="desc" placeholder="Комментарий"></textarea>
																		</label>
																	</div>
																</div>
																<div className="form-title">Автотраспорт</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Рыночная стоимость</span>
																			<input type="text" {...bindAutoMarketValue} placeholder="Рыночная стоимость" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Способ приобретения</span>
																			<input type="text" {...bindAutoAcquisitionMethod} placeholder="Способ приобретения" className="double" />
																		</label>
																	</div>
																</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Комментарий</span>
																			<textarea {...bindAutoComment} className="desc" placeholder="Комментарий"></textarea>
																		</label>
																	</div>
																</div>
																<div className="form-title">Дополнительный доход</div>
																<div className="row">
																	<div className="cell">
																		<label>
																			<span>Сумма</span>
																			<input type="text" {...bindAdditionalIncomeSum} placeholder="Сумма" />
																		</label>
																	</div>
																	<div className="cell">
																		<label>
																			<span>Источник</span>
																			<input type="text" {...bindAdditionalIncomeSource} placeholder="Источник" className="double" />
																		</label>
																	</div>
																</div>
															</div>
														</div> : null}
												</div>



											</div>







											
											

										</div> : null}

									{tab === 2 ?
										<div>
											2
										</div>
										: null}

								</div>
							</div>
							<div className="client-info-info">
								<div className="client-info-block">
									<div className="title">Заметки по клиенту</div>
									<textarea {...bindClientComment} className="desc" placeholder="Заметки по клиенту"></textarea>
									<button type="text">Сохранить</button>
								</div>
								<div className="client-info-block">
									<div className="title">Проверки по клиенту</div>
									<div className="row row-first"><img src={iconCheck} alt="" className="check-ok" />ФМС</div>
									<div className="row"><img src={iconCheck} alt="" className="check-ok" />РФМ: не найден в списке террористов</div>
									<div className="row"><img src={iconCheck} alt="" className="check-ok" />Решение МВК: не найдено</div>
									<div className="row"><img src={iconCheck} alt="" className="check-ok" />ФРОМУ: не найден в списке</div>
								</div>


								<div className="client-info-block">
									<div className="title">Рассылки</div>
									<div className="row row-first"><img src={iconCheck} alt="" className="check-ok" />Клиент находится в SMS рассылке</div>
									<div className="row"><img src={iconCheck} alt="" className="check-ok" />Клиент находится в Email рассылке</div>
								</div>
								<div className="client-info-block">
									<div className="title">Карты клиента</div>
									<div className="row row-first">
										2202 20** **** 3836<br/>
										UNKNOWN NAME<br/>
										02/2025
									</div>
								</div>

								<div className="client-info-block client-info-summary">
									<div className="title">Информация о клиенте</div>
									<div className="row row-first">Специалист (АО ФИРМА "АГРОКОМПЛЕКС" ИМ. Н.И.ТКАЧЕВА)</div>
									<div className="row">Создан <b>13.02.2024</b></div>
									<div className="row">Числится в Подразделении: <b>Основное</b></div>
									<div className="row">Закреплен за сотрудником по последнему мониторингу <b>Онлайн</b></div>
									<div className="row">Этап бизнес процесса <b>Ручная проверка</b></div>
								</div>



							</div>
						</div>
						



					</div>
					<Alert />
				</form>
			}
		</TemplateMenu>
	);
};

export default ClientScreen;