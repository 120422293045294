import {Link} from 'react-router-dom';

// plug-ins
import Moment from 'moment';

// helpers
import {phoneFormatter,moneyFormat,yearsOldGet} from '../../Globals/Utils';

// globals
import {userStatusName,commonStatusName,userGenderName} from '../../Globals/Constants';

// styles
import './styles.css';

const linKeys = ['name','firstName','clientName','id'];

const DataGrid = ({title,keys,data,dataFull,tileContent,link,linkComponent,notFound,controlPanel,controlAdd}) => {
	const linkShow = (link, item, parent) => {
		if (linKeys.includes(item.key)) return linkComponent ? linkComponent(idGet(parent), item.show) : <Link to={`${link}/${idGet(parent)}`}>{item.show}</Link>;
		return item.show;
	}
	const idGet = (parent) => {
		for (const item of parent) {
			if (item.key === '_id') return item.value;
			if (item.key === 'id') return item.value;
		}
		return 0;
	}
	return (
		<div className="dg-container">
			<h4>
				{title}
				{controlAdd || null}
			</h4>
			{dataFull === null || dataFull.length === 0 ? notFound
				:
					<div className="list">
						<div className="controls">
							{controlPanel || null}
						</div>
						{data === null || data.length === 0 ? notFound
							:
								tileContent ||
									<table>
										<thead>
											<tr>
												{keys.map((v,i) => <th key={i}>{v}</th>)}
											</tr>
										</thead>
										<tbody>
											{data.map((v,i) => <tr key={i}>
												{v.map((r,j) => {
													if (Array.isArray(r)) {
														return <td key={j} className="row-union">
															{r.map((item,k) => <div key={k}>{linkShow(link,item,v)}</div>)}
														</td>
													}
													return r.key === '_id' || r.key === 'id' ? null : <td key={j}>{linkShow(link,r,v)}</td>
												})}

											</tr>)}
										</tbody>
									</table>
						}
					</div>
			}
		</div>
	);
};
export default DataGrid;

export const dgDataPrepare = (data, tableName, keys = [], collection = {}, options) => {
	const out = [];
	data.forEach((v) => {
		const inner = [];
		if (keys.length === 0) {
			for (const k in v) inner.push(valueGet(k, v[k], tableName, collection, options));
		} else {
			keys.forEach((k) => {
				if (options && options.unionkeys && options.unionkeys[k]) {
					const union = [];
					options.unionkeys[k].forEach((u) => union.push(valueGet(u, v[u], tableName, collection, options)));
					inner.push([...union, valueGet(k, v[k], tableName, collection)]);
				} else inner.push(valueGet(k, v[k], tableName, collection, options));
			});
		}
		out.push(inner);
	});
	return out;
}
const valueGet = (key, value, tableName, collection, options) => {
	let show = value;
	switch (key) {
		case 'gender':
			show = userGenderName[value];
			break;
		case 'phone':
			show = value ? phoneFormatter(`${value}`) : value;
			break;
		case 'createdAt':
		case 'updatedAt':
			show = Moment(value).format('DD.MM.y, H:mm');
			break;
		case 'birthdate':
			show = Moment(value).format('DD.MM.y');
			if (options && options.ageShow) show += ` (${yearsOldGet(value)} лет)`;
			break;
		case 'status':
			show = statusGet(tableName, value);
			break;
		case 'clientId':
			if (collection && collection['clients']) {
				const client = collection['clients'].find(f => f.id === value);
				show = client ? <Link to={`/client/${client.id}`}>{client.name}</Link> : '—';
			} else show = null;
			break;
		case 'amount':
		case 'sum':
		case 'debt':
			show = moneyFormat(value);
			break;
		case 'image':
			show = <img src={value} className="photo" alt="" />;
			break;
		default: break;
	}
	return {key,value,show};
}
const statusGet = (tableName, value) => {
	switch(tableName) {
		case 'clients':
			return userStatusName[value];
		default: return commonStatusName[value];
	}
}