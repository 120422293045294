import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// models
//import { Orders } from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import { API } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import iconMenu from '../../Assets/Images/icons/menu.svg';
import iconMenuClose from '../../Assets/Images/icons/close.svg';
import logo from '../../Assets/Images/logo.svg';

const TemplateMenu = (props) => {
	const [Modal, open, close] = useModal('root', {preventScroll:true});
	const [user, setUser] = useState(null);
	const done = () => {
		ls();
		close();
		window.location.href='/login';
	}
	const show = (e) => {
		e.preventDefault();
		open();
	}
	const hide = (e) => {
		e.preventDefault();
		close();
	}
	const openMenu = (e) => {
		let menu = document.querySelector('.menu');
		menu.classList.add('active')
	}
	const closeMenu = (e) => {
		let menu = document.querySelector('.menu');
		menu.classList.remove('active')
	}
	useEffect(() => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		setUser(user);
	}, []);
	const isselect = (page) => window.location.pathname.indexOf(page) !== -1 ? 'selected' : null;
	return (
		<div className="container">
			<div className="menu-open" onClick={openMenu}>
				<img src={iconMenu} alt="menu-icon"/>
			</div>
			<div className="menu">
				<div className="menu-close" onClick={closeMenu}>
					<img src={iconMenuClose} alt="" />
				</div>
				<div className="menu-inner">
					<img src={logo} alt="Capitalina" className="logo" />
					<ul className="menu-first">
						<li className={isselect('dashboard')}>
							<Link to={'/dashboard'}>Кредитная деятельность</Link>
							<ul className="menu-second">
								<li className={isselect('clients')}>
									<Link to={'/clients'}>Клиенты</Link>
									<ul className="menu-third menu-third-controls">
										<li className={isselect('clients')}><Link to={'/clients'}>Список клиентов</Link></li>
									</ul>
									<ul className="menu-third">
										<li className="selected"><Link to={'/dashboard'}>Захаренко Екатерина Владимировна</Link></li>
										<li><Link to={'/dashboard'}>Матвеев Вадим Владимирович</Link></li>
										<li><Link to={'/dashboard'}>Смирнова Анна Владимировна</Link></li>
										<li><Link to={'/dashboard'}>Татьянченко Наталья Александровна</Link></li>
									</ul>
								</li>
								<li><Link to={'/dashboard'} className={isselect('dashboard')}>Заявки</Link></li>
								<li><Link to={'/dashboard'} className={isselect('dashboard')}>Контракты</Link></li>
							</ul>
						</li>
						<li><Link to={'/market'}>Статистика</Link></li>
						<li><Link to={'/orders'}>Справочники</Link></li>
						<li><Link to={'/histories'}>Настройка ЛК</Link></li>
						<li className="separator"><Link to={'/histories'}>Администрирование</Link></li>
					</ul>
					<ul className="logout">
						<li><Link to={'/'} onClick={show}>Выйти</Link></li>
					</ul>
				</div>
				<div className="menu-inner">
					<div className="copyright">
						версия {API.version}<br/>
						2021 &copy; Capitalina<br/>
						Все права защищены
					</div>
				</div>

			</div>
			<div className="main">
				{props.children}
			</div>
			<Modal>
				<div className="confirmcontainer">
					<h4>Подтвердите пожалуйста</h4>
					Вы уверены что хотите выйти из системы администрирования?
					<div className="buttons">
						<button onClick={done}>Да</button>
						<Link to={'/'} className="cancel" onClick={hide}>Отмена</Link>
					</div>
				</div>
			</Modal>
		</div>
	);
};
export default TemplateMenu;