import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import { AreaChart, XAxis, Area } from 'recharts';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';

// models
//import {Settings} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// styles
import './styles.css';

// icons
const icons = {
	down: <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M9.70711 10.7071C10.0976 10.3166 10.0976 9.68342 9.70711 9.29289C9.31658 8.90237 8.68342 8.90237 8.29289 9.29289L7 10.5858L7 3C7 2.44771 6.55228 2 6 2C5.44772 2 5 2.44771 5 3L5 10.5858L3.70711 9.29289C3.31658 8.90237 2.68342 8.90237 2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071L5.29289 13.7071C5.68342 14.0976 6.31658 14.0976 6.70711 13.7071L9.70711 10.7071Z" fill="currentColor"></path></svg>,
	up: <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2.29289 5.29289C1.90237 5.68342 1.90237 6.31658 2.29289 6.70711C2.68342 7.09763 3.31658 7.09763 3.70711 6.70711L5 5.41421V13C5 13.5523 5.44772 14 6 14C6.55228 14 7 13.5523 7 13V5.41421L8.29289 6.70711C8.68342 7.09763 9.31658 7.09763 9.70711 6.70711C10.0976 6.31658 10.0976 5.68342 9.70711 5.29289L6.70711 2.29289C6.31658 1.90237 5.68342 1.90237 5.29289 2.29289L2.29289 5.29289Z" fill="currentColor"></path></svg>
}

const DashboardScreen = () => {
	const [orders, setOrders] = useState(null);
	const [loading, setLoading] = useState(true);

	const avgRideTime = [
		{time:5},
		{time:12},
		{time:7},
		{time:13},
		{time:10},
		{time:21},
		{time:32},
		{time:28},
		{time:40},
		{time:38}
	];
	const avgAmount = [
		{amount:850},
		{amount:800},
		{amount:750},
		{amount:450},
		{amount:250},
		{amount:500},
		{amount:430}
	];
	const cntOrders = [
		{cnt:4},
		{cnt:12}
	];
	const cntOrdersCancel = [
		{cnt:5},
		{cnt:2},
	];

	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const orders = [
				{id:10001,name:'Иванов Иван Иванович',amount:9500,userId:101},
				{id:10002,name:'Иванов Иван Иванович',amount:9500,userId:102},
				{id:10003,name:'Иванов Иван Иванович',amount:9500,userId:103}
			];
			setOrders(orders);
		};
		return async () => {
			await dataGet();
			setLoading(false);
		};
	}, []);
	const chartShow = (data, key, options) => {
		const color = data[0][key] > data[1][key] ? '#f5523a' : '#1cc052';
		const id = `color${Math.random()}`;
		return <AreaChart width={options.width} height={options.height} data={data}>
			<defs>
				<linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
					<stop offset="10%" stopColor={color} stopOpacity={.25}/>
					<stop offset="90%" stopColor={color} stopOpacity={0}/>
				</linearGradient>
			</defs>
			<XAxis hide={true} dataKey={key} />
			<Area type="monotone" dataKey={key} stroke={color} strokeWidth={3} fillOpacity={1} fill={`url(#${id})`} />
		</AreaChart>
	}


	/*
	const chartInfo = (data, key, prefix) => {
		const color = data[0][key] > data[1][key] ? '#F5523A' : '#1CC052';
		const arrow = data[0][key] > data[1][key] ? icons.down : icons.up;
		return <div className="chart-info">
			{data[1][key]} {prefix}
			<span style={{color}} className="chart-info-arrow">{arrow} {data[0][key]} {prefix}</span>
		</div>
	}
	*/
	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<div className="dashboard-container">
					<div className="blocks">

						{/* <div className="block-wrap">
							<div className="block block-wrap-wide">
								<b>Среднее время доставки</b>
								<div className="chart">
									{chartShow(avgRideTime, 'time')}
									{chartInfo(avgRideTime, 'time', 'мин')}
								</div>
							</div>
						</div> */}

						<div className="block-wrap block-wrap-wide">
							<div className="block">
								<div className="block-oneline">
									<div className="block-title">
										<b>346</b>
										<div>заявок на рассмотрении</div>
									</div>
									<div className="block-info">
										<div className="chart">
											{chartShow(avgRideTime, 'time', {width:260,height:100})}
										</div>
									</div>
								</div>
								<div className="list">
									{orders && orders.map((v,i) => <div className="row" key={i}>
										<div><Link to={`/order/${v.id}`}>{v.id}</Link></div>
										<div className="amount">{v.amount}</div>
										<div className="name"><Link to={`/user/${v.userId}`}>{v.name}</Link></div>
									</div>)}
								</div>
								<div className="control">

								</div>
							</div>
						</div>



						<div className="block-wrap block-wrap-wide">
							<div className="block">
								<div className="block-oneline">
									<div className="block-title">
										<b>346</b>
										<div>ожидающих рассмотрения заявок</div>
									</div>
									<div className="block-info">
										<div className="chart">
											{chartShow(avgAmount, 'amount', {width:260,height:100})}
										</div>
									</div>
								</div>
								<div className="">
								</div>
							</div>
						</div>

						{/* <div className="block-wrap block-wrap-wide">
							<div className="block">
								<b>Среднее время доставки</b>
								<div className="chart">
									{chartShow(avgRideTime, 'time')}
									{chartInfo(avgRideTime, 'time', 'мин')}
								</div>
							</div>
						</div> */}

					</div>




{/* 
					<div className="blocks">
						<div className="block-wrap">
							<div className="block">
								<b>Средний чек</b>
								<div className="chart">
									{chartShow(avgAmount, 'amount')}
									{chartInfo(avgAmount, 'amount', '₽')}
								</div>
							</div>
						</div>
						<div className="block-wrap">
							<div className="block">
								<b>Количество заказов</b>
								<div className="chart">
									{chartShow(cntOrders, 'cnt')}
									{chartInfo(cntOrders, 'cnt', 'шт')}
								</div>
							</div>
						</div>
						<div className="block-wrap">
							<div className="block">
								<b>Количество отмен</b>
								<div className="chart">
									{chartShow(cntOrdersCancel, 'cnt')}
									{chartInfo(cntOrdersCancel, 'cnt', 'шт')}
								</div>
							</div>
						</div>
					</div> */}


				</div>
			}
		</TemplateMenu>
	);
};

export default DashboardScreen;