import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
//import {Clients} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import { userGender } from '../../Globals/Constants';

// styles
import './styles.css';

const ClientsScreen = (props) => {
	const [id, setId] = useState(props.match.params.id||null);
	const [clients, setClients] = useState(null);
	const [items, setItems] = useState(null);
	const [itemId, setItemId] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}

			const clients = [
				{id:1234027, name:'Глушко Юлия Александровна', gender:userGender.FEMALE, birthdate:'1993-05-18', phone:'79146601821',image:'https://fs-green-k8s.brainysoft.ru/web/files/thumb/capitalina/12757987/800dce62d44956b71676501c0806349e8d57c9c6f8f266526dc0da7f358bc675847c345160eae2236459b8b496339c8381a0741bcd1743643d9c76dfbbad4409'},
				{id:1234026, name:'Журавлева Наталья Сергеевна', gender:userGender.FEMALE, birthdate:'1986-07-23', phone:'79003177857',image:'https://fs-green-k8s.brainysoft.ru/web/files/thumb/capitalina/12757987/800dce62d44956b71676501c0806349e8d57c9c6f8f266526dc0da7f358bc675847c345160eae2236459b8b496339c8381a0741bcd1743643d9c76dfbbad4409'},
				{id:1234025, name:'Кузнецова Анна Владимировна', gender:userGender.FEMALE, birthdate:'1986-07-23', phone:'79003177857',image:'http://localhost/assets/products/IMG_0017-14.jpg'},
			];
			setItems(dataSet(clients));
		};
		return async () => {
			await dataGet();
			setLoading(false);
		};
	}, []);


	const dataSet = (data) => dgDataPrepare(data, 'clients', ['id','name','gender','birthdate','phone'], null, {unionkeys:{name:['image']},ageShow:true});

	const showItem = (id) => {
		setItemId(id || 0);
		return false;
	}

	const handleStatus = (e) => {
		// let status = e.target.value;
		// let d = dataFull;
		// if (empty(status)) status = null;
		// if (status !== null) d = dataStatus(d, parseInt(status));
		// setOrders(dataSet(d));
		// setStatus(status);
	}

	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<div className="clients-container">
					<div className="blocks">
						<DataGrid
							title={'Список клиентов'} 
							keys={['Клиент','Пол','Дата рождения', 'Телефон']}
							link={'client'}
							//linkComponent={(id, name) => <Link to={'#'} onClick={() => showItem(id)}>{name}</Link>}
							data={items}
							dataFull={items}
							controlAdd={<Link to={'#'} onClick={() => showItem()}>Добавить</Link>}
							controlPanel={<>
								<div></div>
								<div>
									<span>Статус</span>
									<select onChange={handleStatus}>
										<option value=" ">все</option>
										{/* {orderStatusName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)} */}
									</select>
								</div>
							</>}
							notFound={<NotFound />} />
					</div>


				</div>
			}
		</TemplateMenu>
	);
};

export default ClientsScreen;