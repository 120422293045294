/*
 * (c) pavit.design, 2024
 */

import {API,httpMethods} from '../Constants';

// helpers
import { ls } from '../Localstorage';

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const request = async (endpoint, method, data) => {
	const url = `${API.url}${endpoint}`;
	const headers = new Headers({
		'X-Token':API.key,
		'Content-Type':'application/json'
	});
	const token = ls('token');
	if (token) headers.append('Authorization', `Bearer ${token}`);
	const options = {
		method:method || httpMethods.GET,
		headers
	};
	if (data !== null && data !== undefined) options.body = JSON.stringify(data);
	const response = await fetch(url, options).catch((ex) => {
		console.log('error fetch response:', ex, url, options);
		throw {status:500};
	})
	if (response.status === 200) {
		if (isDevelopmentMode) console.log(response);
		try {
			const json = await response.json();
			if (isDevelopmentMode) console.log(json);
			return json;
		} catch (ex) { }
		return null;
	}
	const error = await response.json();
	console.log('error response. response:', response, url, error);
	throw error;
};

const Http = {
	request
};

export default Http;