const isDevelopmentMode = process.env.NODE_ENV !== 'production';
const url = isDevelopmentMode ? 'http://localhost:8090' : 'https://api.capitalina-api.ru';

const API = {
	url			: `${url}/api/v1/`,
	version		: '1.0.1'
};

const SETTINGS = {
};

const TIMERS = {
};

const httpMethods = {
	GET		: 'GET',
	POST	: 'POST',
	PUT		: 'PUT',
	DELETE	: 'DELETE'
};

const commonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const commonStatusName = ['Не активен','Активен'];

const userStatus = {
	ACTIVE		: 1,
	IN_ACTIVE	: 2,
	BAN			: 3,
	DELETED		: 4
};
const userStatusName = ['','Активен','Не активен','Заблокирован','Удален'];

/* Client */

const clientType = {
	UNSET		: 0,
	BORROWER	: 1
};
const clientTypeName = ['','Заемщик'];

const userGender = {
	UNSET		: 0,
	MALE		: 1,
	FEMALE		: 2
};
const userGenderName = ['','Мужской','Женский'];

const clientFamilyStatus = {
	UNSET		: 0,
	WIDOWER		: 1,
	CIVIL		: 2,
	MARRIED		: 3,
	DIVORCED	: 4,
	SINGLE		: 5
};
const clientFamilyStatusName = ['','Вдовец/Вдова','Гражданский брак','Женат/Замужем','Разведен/Разведена','Холост/Не замужем'];

const clientEducation = {
	UNSET				: 0,
	HIGHER				: 1,
	PRESCHOOL			: 2,
	PRIMARY_SCHOOL		: 3,
	PRIMARY_PROFESSIONAL: 4,
	INCOMPLETE_SECONDARY: 5,
	AFTER_GRADUATION	: 6,
	SECONDARY_SPECIAL	: 7,
	SECONDARY_TECHNICAL	: 8,
	SECONDARY			: 9
};
const clientEducationName = ['','Высшее','Дошкольное','Начальная школа','Начальное профессиональное','Неполное среднее','Послевузовское','Средне-специальное','Средне-техническое','Среднее'];

const clientDocumentType = {
	PASSPORT	: 1,
	DRIVER		: 2,
	OTHER		: 3,
	FOREIGN		: 4,
	NONE		: 5,
	PENSION		: 6
};
const clientDocumentTypeName = ['','Паспорт','Водительское удостоверение','Другой документ','Заграничный паспорт','Нет','Пенсионное удостерение'];

const clientPensionType = {
	UNSET			: 0,
	AGE				: 1,
	EXPERIENCE		: 2,
	AGE_EXPERIENCE	: 3,
	AGE_DEATH		: 4,
	SOCIAL			: 5
};
const clientPensionTypeName = ['','По возрасту','По стажу','По возрасту и стажу','По факту смерти кормильца','Социальная'];

const clientEmployment = {
	UNSET				: 0,
	UNEMPLOYED			: 1,
	BUDGET				: 2,
	IP					: 3,
	OAO					: 4,
	WORKING_PENSIONER	: 5,
	SELF_EMPLOYED		: 6
};
const clientEmploymentName = ['','Работающий','Безработный','Бюджетная организация','ИП','ОАО,ООО,ЗАО','Работающий пенсионер','Самозанятый'];

const clienActivityType = {
	UNSET		: 0,
	PRODUCTION	: 1,
	SERVICES	: 2,
	TRADING		: 3,
	TRANSPORT	: 4
};
const clienActivityTypeName = ['','Производство','Сфера услуг','Торговля','Транспорт'];


export {
	API,
	SETTINGS,
	TIMERS,
	httpMethods,
	commonStatus,
	commonStatusName,
	userStatus,
	userStatusName,
	userGender,
	userGenderName,
	clientType,
	clientTypeName,
	clientFamilyStatus,
	clientFamilyStatusName,
	clientEducation,
	clientEducationName,
	clientDocumentType,
	clientDocumentTypeName,
	clientPensionType,
	clientPensionTypeName,
	clientEmployment,
	clientEmploymentName,
	clienActivityType,
	clienActivityTypeName
};