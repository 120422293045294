// plug-ins
import toast from 'react-simple-toasts';

// styles
import './styles.css';

const config = {
	position:'top-center',
	className:'toast-container',
	maxVisibleToasts:1
};
const defTitles = {
	success:'Успех!',
	warning:'Внимание!',
	error:'Ошибка!'
}

const show = (option, type) => {
	if (!option) return;
	if (typeof option === 'string') option = {message:option};
	const {title, message, istitle} = option;
	type = option.type || 'error';
	if (type === 'error') config.className = `${config.className} error-toast`;
	if (type === 'success') config.className = `${config.className} success-toast`;
	if (type === 'warning') config.className = `${config.className} warning-toast`;
	const text = <>
		{title ? <b>{title}</b> : (istitle ? <b>{defTitles[type]}</b> : null)}
		{message}
	</>;
	toast(text, config);
}

export default {
	show
};