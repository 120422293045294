import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start				from './Screens/Start';
import Login				from './Screens/Login';

import Dashboard			from './Screens/Dashboard';
import Clients				from './Screens/Clients';
import Client				from './Screens/Client';



import Error				from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/dashboard' component={Dashboard} exact />
			<Route path='/clients' component={Clients} exact />
			<Route path='/client' component={Client} exact />
			<Route path='/client/:id' component={Client} exact />



			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;